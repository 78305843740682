var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center h-40"},[_vm._m(0),_c('br'),_c('div',[_c('button',{attrs:{"disabled":_vm.isStartRecording,"id":"btnStart"},on:{"click":function($event){$event.preventDefault();return _vm.startRecording()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.play', {
                class: 'w-50px h-50px d-inline-block object-fill cursor-pointer',
                style: `${_vm.isStartRecording ? 'opacity: 0.5;': ''}`
              }))}})]),_c('button',{attrs:{"disabled":!_vm.isStartRecording,"id":"btnStop"},on:{"click":function($event){$event.preventDefault();return _vm.stopRecording()}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.pop-up-check-ok', {
                class: 'w-50px h-50px d-inline-block object-fill cursor-pointer',
                style: `${!_vm.isStartRecording ? 'opacity: 0.5;': ''}`
              }))}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('video',{staticClass:"video-js vjs-default-skin",attrs:{"id":"myVideo","playsinline":""}},[_c('p',{staticClass:"vjs-no-js"},[_vm._v(" To view this video please enable JavaScript, or consider upgrading to a web browser that "),_c('a',{attrs:{"href":"https://videojs.com/html5-video-support/","target":"_blank"}},[_vm._v(" supports HTML5 video. ")])])])
}]

export { render, staticRenderFns }