<template>
  <div class="text-center">
    <audio id="myAudio" class="video-js vjs-default-skin">
      <p class="vjs-no-js">
        To view this video please enable JavaScript, or consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
          supports HTML5 video.
        </a>
      </p>
    </audio>
    <br>

    <button
        @click.prevent="startRecording()"
        v-bind:disabled="isStartRecording"
        id="btnStart"
    >
          <span
              v-html="getIconByKey('icons.meeting.play', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer',
                  style: `${isStartRecording ? 'opacity: 0.5;': ''}`
                })"

          >
        </span>
    </button>
    <button
        @click.prevent="stopRecording()"
        v-bind:disabled="!isStartRecording"
        id="btnStop"
    >
          <span
              v-html="getIconByKey('icons.meeting.pop-up-check-ok', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer',
                  style: `${!isStartRecording ? 'opacity: 0.5;': ''}`
                })"

          >
        </span>
    </button>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import videojs from 'video.js'
import 'webrtc-adapter'
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';


export default {
  props: {
    uploadUrl: {},
    model: {},
  },
  data() {
    return {
      player: '',
      retake: 0,
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      submitText: 'Submit',
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          deviceButton: false,
          recordToggle: false,
          pipToggle: false
        },
        width: 500,
        height: 300,
        fluid: true,
        plugins: {
          wavesurfer: {
            backend: 'WebAudio',
            waveColor: '#36393b',
            progressColor: 'black',
            debug: true,
            cursorWidth: 1,
            hideScrollbar: true,
            plugins: [
              // enable microphone plugin
              WaveSurfer.microphone.create({
                bufferSize: 4096,
                numberOfInputChannels: 1,
                numberOfOutputChannels: 1,
                constraints: {
                  video: false,
                  audio: true
                }
              })
            ]
          },
          record: {
            pip: false,
            audio: true,
            video: false,
            maxLength: 3600,
            debug: true
          }
        }
      }
    }
  },
  mounted() {
    this.player = videojs('myAudio', this.options, () => {
    });

    // error handling
    this.player.on('deviceReady', () => {
      this.player.record().start();
    });

    this.player.on('deviceError', () => {
    });

    this.player.on('error', (element, error) => {
      console.error(error);
    });

    this.player.on('startRecord', () => {
    });

    this.player.on('stopRecord', () => {
    });

    this.player.on('finishRecord', () => {
      this.isSaveDisabled = false;
      if(Number(this.retake) === 0) {
        this.isRetakeDisabled = false;
      }
      this.$emit('update:model', this.player.recordedData);
    });
  },
  methods: {
    startRecording() {
      this.isStartRecording = true;
      this.player.record().getDevice();
    },
    stopRecording() {
      this.player.record().stopDevice();
    },

    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.retake += 1;
      this.player.record().start();
    }
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>